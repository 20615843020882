import React from "react";
import PropTypes from "prop-types";

const Footer = ({ author, footer }) => (
  <footer>
    <span className="footerCopyrights">© 2020 {author}</span>
    {footer.map(item => (
      <span key={item.title} className="footerCopyrights"><a href={item.path}>{item.title}</a></span>
    ))}
  </footer>
);

Footer.propTypes = {
  author: PropTypes.string,
  footer: PropTypes.array,
};

export default Footer;
